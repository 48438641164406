const civitasSettings = {
	title: "Civitas.Global",
	description: "A democracy-driven news network where everyday citizens can become journalists through strict training and verification processes. Stories are submitted and voted on by local and global communities, with funding provided by a transparent, crowd-funded system.",
	logo: "/logo.png",
	logoBig: "/logo512.png",
	headerBg: 'rgb(95,158,160)',
	footerBg: "rgb(95,158,160)",
	headerTextColor: "#fff",
	footerTextColor: "#fff",
	style: 'critical'
};

export default civitasSettings;
